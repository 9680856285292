import "swiper/swiper.css";
import "swiper/css/effect-creative";

import { Entry } from "contentful";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import SwiperCore, { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { countryDialogOpenedState } from "~/atoms/country-dialog";
import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/cross-country-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Container from "../common/container";
import Icon from "../common/icon";
import styles from "./top-bar.module.scss";

const logClick = (item: string) => console.info("%c[WIP]", "color: red;", ` ${item} click`);

type PropsTopBarCenter = {
  delay: number;
  topBarCenterLinkList?: Entry<Editorials.Link>[];
};

export function TopBarCenter({ delay = 5, topBarCenterLinkList }: PropsTopBarCenter) {
  const t = useTranslations();
  const router = useRouter();
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const topBarLinks = topBarCenterLinkList?.filter(isActiveEntry);
  const delayInMmilliseconds = delay * 1000;

  if (topBarLinks && topBarLinks.length > 0) {
    return (
      <div className={styles.center}>
        {topBarLinks.length > 1 ? (
          <button onClick={() => swiperInstance?.slidePrev()} aria-label={t("a11y.previousSlide")}>
            <Icon name="chevron-left" />
          </button>
        ) : null}
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[Autoplay, EffectCreative]}
          centeredSlides
          grabCursor
          loop
          effect="creative"
          creativeEffect={{
            prev: { translate: ["-100%", 0, 0], opacity: 0 },
            next: { translate: ["100%", 0, 0], opacity: 0 },
          }}
          speed={1500}
          autoplay={{
            delay: delayInMmilliseconds,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          className={styles.swiper}
        >
          {topBarLinks && topBarLinks.length > 0
            ? topBarLinks.map((link) => {
                const { text, url, pageRef, openOnANewTab } = link.fields;
                const inspectorModeTopBarLink = contentfulUtils.useInspectorMode(link);

                if (url || pageRef) {
                  return (
                    <SwiperSlide key={link.sys.id} className={styles.swiperSlide}>
                      <Link
                        {...inspectorModeTopBarLink?.getProps("text")}
                        prefetch={false}
                        href={textUtils.sanitizeContentfulUrl(link, router)}
                        target={openOnANewTab ? "_blank" : undefined}
                        className={styles.topBarCenterLink}
                      >
                        {text}
                      </Link>
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide key={link.sys.id} className={styles.swiperSlide}>
                      <span {...inspectorModeTopBarLink?.getProps("text")}>{text}</span>
                    </SwiperSlide>
                  );
                }
              })
            : null}
        </Swiper>
        {topBarLinks.length > 1 ? (
          <button onClick={() => swiperInstance?.slideNext()} aria-label={t("a11y.nextSlide")}>
            <Icon name="chevron-right" />
          </button>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
}

type PropsTopBar = {
  entry: Entry<Editorials.TopBar>;
};

export default function TopBar({ entry }: PropsTopBar) {
  const router = useRouter();
  const country = crossCountryUtils.getCurrentCountryCode(router);
  const setCountryDialogOpenedState = useSetRecoilState(countryDialogOpenedState);

  function openCountryDialog() {
    setCountryDialogOpenedState(true);
  }

  if (!isActiveEntry(entry)) {
    return null;
  }

  const inspectorModeStoreLink = contentfulUtils.useInspectorMode(entry.fields.storeLink);
  const inspectorModeCustomerHelpCenterLink = contentfulUtils.useInspectorMode(entry.fields.customerHelpCenterLink);

  return (
    <div className={styles.topBar}>
      <Container className={styles.topBarContainer}>
        {entry.fields.storeLink && isActiveEntry(entry.fields.storeLink) ? (
          <Link
            {...inspectorModeStoreLink?.getProps("text")}
            prefetch={false}
            href={textUtils.sanitizeContentfulUrl(entry.fields.storeLink, router)}
            target={entry.fields.storeLink.fields.openOnANewTab ? "_blank" : undefined}
            className={styles.topBarLink}
            onClick={() => logClick("stores")}
          >
            {entry.fields.storeLink.fields.icon && isActiveEntry(entry.fields.storeLink.fields.icon) ? (
              <Icon
                svgMedia={entry.fields.storeLink.fields.icon.fields.svgMedia}
                name={entry.fields.storeLink.fields.icon.fields.svgIconId}
              />
            ) : null}
            <span>{entry.fields.storeLink.fields.text}</span>
          </Link>
        ) : (
          <div />
        )}
        <div className={styles.containerTopBarCenter}>
          <TopBarCenter delay={entry.fields.delay} topBarCenterLinkList={entry.fields.topBarCenterLinkList} />
        </div>
        <div className={styles.topBarWrapper}>
          {entry.fields.customerHelpCenterLink && isActiveEntry(entry.fields.customerHelpCenterLink) ? (
            <Link
              {...inspectorModeCustomerHelpCenterLink?.getProps("text")}
              prefetch={false}
              href={textUtils.sanitizeContentfulUrl(entry.fields.customerHelpCenterLink, router)}
              target={entry.fields.customerHelpCenterLink.fields.openOnANewTab ? "_blank" : undefined}
              className={styles.topBarLink}
              onClick={() => logClick("help")}
            >
              {entry.fields.customerHelpCenterLink.fields.icon &&
              isActiveEntry(entry.fields.customerHelpCenterLink.fields.icon) ? (
                <Icon
                  svgMedia={entry.fields.customerHelpCenterLink.fields.icon.fields.svgMedia}
                  name={entry.fields.customerHelpCenterLink.fields.icon.fields.svgIconId}
                />
              ) : null}
              <span>{entry.fields.customerHelpCenterLink.fields.text}</span>
            </Link>
          ) : null}
          <button className={styles.topBarLink} onClick={() => openCountryDialog()}>
            <span>{router.locale?.replace(/(\w*)\s*-\s*(\w*)/, "$2 | $1")}</span>
            <Image
              unoptimized
              width={16}
              height={16}
              alt={country}
              src={`/next/assets/flags/${country.toLowerCase()}.svg`}
            />
          </button>
        </div>
      </Container>
    </div>
  );
}
