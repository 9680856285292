import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { Entry } from "contentful";
import dynamic from "next/dynamic";
import { Key } from "react";

const CheckYourBalance = dynamic(() => import("../common/check-your-balance"));
const ClientelaLink = dynamic(() => import("../common/clientela-link"));
const Footer = dynamic(() => import("../layout/footer"));
const Prefooter = dynamic(() => import("../layout/prefooter"));
const BrandCarousel = dynamic(() => import("./brand-carousel/brand-carousel"));
const EditorialAccordion = dynamic(() => import("./editorial-accordion/editorial-accordion"));
const EditorialAsset = dynamic(() => import("./editorial-asset/editorial-asset"));
const EditorialBanner = dynamic(() => import("./editorial-banner/editorial-banner"));
const EditorialCards = dynamic(() => import("./editorial-cards/editorial-cards"));
const EditorialGrid = dynamic(() => import("./editorial-columns/editorial-grid"));
const EditorialDescription = dynamic(() => import("./editorial-description/editorial-description"));
const EditorialTabbed = dynamic(() => import("./editorial-tabbed/editorial-tabbed"));
const GetTheLook = dynamic(() => import("./get-the-look/get-the-look"));
const Hero = dynamic(() => import("./hero/hero"));
const HeroSlide = dynamic(() => import("./hero/hero-slide"));
const HeroVideo = dynamic(() => import("./hero/hero-video"));
const JsonPreview = dynamic(() => import("./json-preview"));
const LinksCarousel = dynamic(() => import("./links-carousel/links-carousel"));
const LoyaltyTable = dynamic(() => import("./loyalty-table/loyalty-table"));
const ProductCarousel = dynamic(() => import("./product-carousel/product-carousel"));
const RevieveModalTrigger = dynamic(() => import("./revieve-modal-trigger/revieve-modal-trigger"));
const ServicesCarousel = dynamic(() => import("./services-carousel/services-carousel"));
const SmartTribuneFaq = dynamic(() => import("./smart-tribune-faq/smart-tribune-faq"));
const SocialWall = dynamic(() => import("./social-wall/social-wall"));
const TabbedHeader = dynamic(() => import("./tabbed-header/tabbed-header"));
const TabbedProductCarousel = dynamic(() => import("./tabbed-product-carousel/tabbed-product-carousel"));
const TimedEditorialCarousel = dynamic(() => import("./timed-editorial-carousel/timed-editorial-carousel"));
const EditorialProductListing = dynamic(() => import("./editorial-product-listing/editorial-product-listing"));

export type EditorialEntryProps = {
  entry: Entry<unknown>;
  key?: Key;
};

export default function EditorialEntry(props: EditorialEntryProps) {
  const liveEntry = useContentfulLiveUpdates(props.entry);

  switch (liveEntry.sys.contentType.sys.id) {
    case "hero":
      return <Hero entry={liveEntry} />;
    case "heroSlide":
      return <HeroSlide entry={liveEntry} />;
    case "heroVideo":
      return <HeroVideo entry={liveEntry} />;
    case "footer":
      return <Footer entry={liveEntry} />;
    case "prefooter":
      return <Prefooter entry={liveEntry} />;
    case "timedEditorialCarousel":
      return <TimedEditorialCarousel entry={liveEntry} />;
    case "productCarousel":
      return <ProductCarousel entry={liveEntry} />;
    case "servicesCarousel":
      return <ServicesCarousel entry={liveEntry} />;
    case "brandCarousel":
      return <BrandCarousel entry={liveEntry} />;
    case "linksCarousel":
      return <LinksCarousel entry={liveEntry} />;
    case "editorialAccordions":
      return <EditorialAccordion entry={liveEntry} />;
    case "editorialGrid":
      return <EditorialGrid entry={liveEntry} />;
    case "loyaltyTable":
      return <LoyaltyTable entry={liveEntry} />;
    case "tabbedHeader":
      return <TabbedHeader entry={liveEntry} />;
    case "editorialBanner":
      return <EditorialBanner entry={liveEntry} />;
    case "editorialTabbed":
      return <EditorialTabbed entry={liveEntry} />;
    case "tabbedProductCarousel":
      return <TabbedProductCarousel entry={liveEntry} />;
    case "editorialDescription":
      return <EditorialDescription entry={liveEntry} />;
    case "editorialCards":
      return <EditorialCards entry={liveEntry} />;
    case "breakpointAwareAsset":
      return <EditorialAsset entry={liveEntry} />;
    case "revieveModalTrigger":
      return <RevieveModalTrigger entry={liveEntry} variant="primary" />;
    case "clientelaLink":
      return <ClientelaLink entry={liveEntry} />;
    case "getTheLook":
      return <GetTheLook entry={liveEntry} />;
    case "socialWall":
      return <SocialWall entry={liveEntry} />;
    case "smartTribuneFaq":
      return <SmartTribuneFaq entry={liveEntry} />;
    case "checkYourBalance":
      return <CheckYourBalance entry={liveEntry} />;
    case "editorialProductListing":
      return <EditorialProductListing entry={liveEntry} />;
    default: {
      if (process.env.NODE_ENV === "development") {
        return <JsonPreview {...props} />;
      }

      return null;
    }
  }
}
